var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:_vm.pantalla.sub_tabla || !_vm.shwtoolbar
      ? ''
      : 'm-shadow mx-auto text-center pa-1 m-rouded',attrs:{"flat":_vm.pantalla.sub_tabla || !_vm.shwtoolbar}},[_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.datasource,"search":_vm.search},on:{"click:row":function($event){_vm.dblclick($event, function() {}, _vm.editar_rw)}},scopedSlots:_vm._u([(_vm.shwtoolbar)?{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.pantalla.nombre))]),_c('v-spacer'),_c('Xbtn',{attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.refresh()}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Refrescar ")]},proxy:true}],null,false,977885133)},[_c('v-icon',[_vm._v("refresh")])],1),(_vm.pantalla.anyadir)?_c('Xbtn',{ref:"ref_add",attrs:{"permisos":_vm.permission_new,"icon":"","color":"primary"},on:{"click":function($event){return _vm.add_new()}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Añadir nuevo ")]},proxy:true}],null,false,1494192385)},[_c('v-icon',[_vm._v("add_circle_outline")])],1):_vm._e(),_vm._t("before_search"),_c('v-text-field',{staticClass:"ml-4",attrs:{"append-icon":"search","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._t("after_search")],2),_c('p',{staticClass:"ml-2 mr-2"},[_vm._v(" "+_vm._s(_vm.pantalla.descripcion)+" ")])]},proxy:true}:null,_vm._l((_vm.Override_tipos_datos),function(column){return {key:("item." + (column.data_bilding)),fn:function(ref){
      var item = ref.item;
return [(column.tipo_de_dato.nombre === 'boolean')?_c('v-chip',{attrs:{"outlined":"","color":_vm.get_valor(item, column.data_bilding) ? 'green' : 'red'}},[_vm._v(_vm._s(_vm.get_valor(item, column.data_bilding) ? "Sí" : "No"))]):(column.tipo_de_dato.nombre === 'Date')?[_vm._v(" "+_vm._s(_vm.$format_date(_vm.get_valor(item, column.data_bilding)))+" ")]:(column.tipo_de_dato.nombre === 'Icono')?[_c('i',{class:_vm.get_valor(item, column.data_bilding)})]:(column.tipo_de_dato.nombre === 'Color')?[_c('div',{style:('height: 15px;width: 15px; background-color:' +
              _vm.get_valor(item, column.data_bilding) +
              '; border-radius: 50%;')})]:(column.tipo_de_dato.nombre === 'Imagen usuario')?[_c('UserAvatar',{attrs:{"id_user":_vm.get_valor(item, column.data_bilding)}})]:(column.tipo_de_dato.nombre === 'Progress')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('progress',_vm._g(_vm._b({style:('width: 80px; background-color:rgb(0,' +
                  _vm.get_valor(item, column.data_bilding) +
                  ', 0);border-radius: 50px; height: 6px;'),attrs:{"max":"100"},domProps:{"value":_vm.get_valor(item, column.data_bilding)}},'progress',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.get_valor(item, column.data_bilding))+" %")])])]:[_vm._v(" "+_vm._s(_vm.get_valor(item, column.data_bilding))+" ")]]}}}),{key:"item.acciones",fn:function(ref){
                  var item = ref.item;
return [(_vm.pantalla.editar)?_c('Xbtn',{ref:"ref_update",attrs:{"small":"","icon":"","color":"primary","permisos":_vm.permission_update},on:{"click":function($event){return _vm.editar_rw(item)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Editar ")]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("edit")])],1):_vm._e(),(_vm.pantalla.borrar)?_c('Xbtn',{ref:"ref_borrar",attrs:{"small":"","icon":"","color":"red","permisos":_vm.permission_delete},on:{"click":function($event){return _vm.eliminar(item)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Eliminar ")]},proxy:true}],null,true)},[_c('v-icon',[_vm._v("delete")])],1):_vm._e(),_vm._t("before_acciones",null,{"field":item})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }